$(window).on('load', function () { // makes sure the whole site is loaded
  $('.loader').fadeOut(); // will first fade out the loading animation
  $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
  $('body').delay(350).css({ 'overflow': 'visible' });
});

$(document).ready(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 150) {
      $('.main-navbar').addClass('sticky-top');
    } else {
      $('.main-navbar').removeClass('sticky-top');
    }
  });
});

